import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

/**
 * Footer component that displays legal and company-related links, utilizing internationalization
 * for the link text. This component presents standard legal information including terms and
 * conditions, imprint, and data protection policies, along with a copyright notice.
 *
 * @returns {JSX.Element} The rendered footer component.
 */
function Footer() {
    // Access the translation function from react-i18next for localizing content.
    const { t } = useTranslation();
    return (
        <div className="footer">
            <p>
                <a href="http://www.copyshop-bochum.de/agb/">{t("AGB")}</a>
                <span className='pipe'>|</span>
                <a href="http://www.copyshop-bochum.de/impressum/">{t("impressum")}</a>
                <span className='pipe'>|</span>
                <a href="http://www.copyshop-bochum.de/datenschutz/">{t("data-protection")}</a>
                <br />
                Copyright © 2024 Druckhaus Bochum GmbH
            </p>
        </div>
    );
}

export default Footer;