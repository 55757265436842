import { useEffect, useState, RefObject } from 'react';

/**
 * A custom React hook that provides resized dimensions for a given HTML element. 
 * This hook utilizes a `ResizeObserver` to monitor changes in the element's size and adjusts its dimensions.
 *
 * @param {RefObject<HTMLElement>} containerRef - A React ref object pointing to the element to be observed.
 * @returns {Object} An object containing the resized width and height of the element.
 */
const useResizedDimensions = (containerRef: RefObject<HTMLElement>) => {
    // State to store the dimensions of the observed element
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // Define a resize observer that updates dimensions state upon element resize
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (!entries || !entries[0]) return; // Ensure entries are available and non-empty
            const { width, height } = entries[0].contentRect; // Destructure dimensions from the first entry

            // Set the state with adjusted dimensions
            setDimensions({
                width: width * 0.5,
                height: height * 0.5
            });
        });

        // Get the current element from the ref and observe it for resizing
        const element = containerRef.current;
        if (element) {
            resizeObserver.observe(element);
        }

        // Cleanup function to disconnect the observer when the component unmounts or ref changes
        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]); // Effect dependencies include only the containerRef to prevent excessive runs

    return dimensions;
};

export default useResizedDimensions;

