import { PDFPageProxy } from 'pdfjs-dist';

/**
 * Processes a PDF page off-screen to calculate the count of non-white/none-transparent pixels.
 * Renders the PDF page onto an off-screen canvas, scans the pixel data,
 * and updates the pixel counts accordingly.
 * 
 * @param {PDFPageProxy} page - The PDF.js page object from a loaded document.
 * @param {Function} setPixelCount - state to set the count of non-white pixels.
 * @param {Function} setTotalPixels - state to set the total count of pixels.
 */
export async function processOffScreenPdfPage(page: PDFPageProxy, setPixelCount: (count: number) => void, setTotalPixels: (count: number) => void) {
    const offScreenCanvas = document.createElement('canvas');
    const scale = 1;
    const viewport = page.getViewport({ scale });
    offScreenCanvas.width = viewport.width;
    offScreenCanvas.height = viewport.height;

    const offScreenCtx = offScreenCanvas.getContext('2d');
    if (!offScreenCtx) {
        throw new Error('Failed to create 2D context');
    }

    const renderContext = {
        canvasContext: offScreenCtx,
        viewport: viewport
    };

    await page.render(renderContext).promise;

    const imageData = offScreenCtx.getImageData(0, 0, viewport.width, viewport.height);
    let totalPixels = 0;
    let nonWhitePixels = 0;
    for (let i = 0; i < imageData.data.length; i += 4) {
        totalPixels++;
        if (imageData.data[i] !== 255 || imageData.data[i + 1] !== 255 || imageData.data[i + 2] !== 255 || imageData.data[i + 3] !== 255) {
            nonWhitePixels++;
        }
    }
    setPixelCount(nonWhitePixels);
    setTotalPixels(totalPixels);
}
