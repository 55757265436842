import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import MenuSelector from './components/MenuForPlot/CustomMenu';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import useResizedDimensions from './hooks/useResizedDimensions';
import { calculatePrice } from './components/utils/calculatePrice';
import { processOffScreenPdfPage } from './components/utils/pdfOffScreenPixelProcessing';
import { processInputFile } from './components/utils/inputFileProcessing';
import { PageDimensions } from './components/utils/types';
import { getPdfDimensions } from './components/utils/pdfDimensions';
import { getImageDimensions } from './components/utils/imageDimensions';


// Initialize PDF.js worker from CDN for PDF rendering
// Add this line to ensure proper loading of PDFJS worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * Main application component handling the UI and state management for file processing,
 * PDF rendering, and interactive menu options for plotting and paper sorting.
 */
function App() {
  const { t } = useTranslation(); // Hook for handling internationalization

  const [showLoadingScreen, setShowLoadingScreen] = useState(false);  // State for loading screen visibility

  // State management for file interaction and UI responsiveness 
  const [isDragging, setIsDragging] = useState(false); // Track drag state
  const [droppedFile, setDroppedFile] = useState<{
    file: File | null;
    name: string;
    type: string;
  }>({
    file: null,
    name: "",
    type: "",
  });

  const [numPages, setNumPages] = useState<number>(0);  // Number of pages in a PDF
  const [pageNumber, setPageNumber] = useState<number>(1);  // Current page being displayed

  const [isFileValid, setIsFileValid] = useState<boolean | undefined>(undefined);  // Validity of the processed file
  const [errorMessage, setErrorMessage] = useState<string | null>(null);  // Error message for file validation

  const [fileDimensions, setFileDimensions] = useState<PageDimensions[]>([]);
  // Dimensions of the loaded file
  const currentDimensions = fileDimensions.find(d => d.pageNum === pageNumber);

  // Dynamic calculation of container dimensions to adjust page rendering
  const containerRef = useRef(null);
  const { width, height } = useResizedDimensions(containerRef);
  const scaledWidth = width * 0.9;  // Adjust width for inner padding or margin
  const scaledHeight = height * 0.9;  // Adjust height similarly

  // State management for user interface interactions related to file processing
  const [buttonPosition, setButtonPosition] = useState<"top" | "bottom">("bottom");

  const [pixelCount, setPixelCount] = useState<number>(0); // Count of non-white pixels
  const [totalPixels, setTotalPixels] = useState<number>(0);  // Total pixels of the current page

  const CAD_THRESHOLD: number = 20;
  const GRAPHIC_THRESHOLD: number = 50;

  // Menu and processing states 
  const [plotSize, setPlotSize] = useState<keyof typeof plotSizes | null>(null);  // Selected plot size
  const [isMenuOpen, setMenuOpen] = useState({ plotSize: false, paperSort: false });  // Menu visibility states
  const [paperSort, setPaperSort] = useState<string | null>(null);  // Selected paper sort

  // Definitions for plot sizes and paper sorts with convention mapping
  // Ensure that the plotSize is of type 'A0' | 'A1' | 'A2' | 'A3'
  const plotSizes: {
    A0: string;
    A1: string;
    A2: string;
    A3: string
  } = {
    A0: 'DIN A0',
    A1: 'DIN A1',
    A2: 'DIN A2',
    A3: 'DIN A3'
  };
  const paperSorts: Record<string, string> = {
    /*
      conventions used:
      [size]_[weight]_[type]  -> e.g. a3_80_x

        size:
        - 'a3' stands for A3 paper
        - 'p' stands for plot paper

        type:
        - '' stands for standard (not matt, not glossy and so on)
        - 'm' stands for matt
        - 'g' stands for glossy
        - 'f' stands for Fotopaper
        - 'r' stands for recycling paper
        - 's' stantds for synthetic paper
        - 'c' stands for colored paper    
    */
    'a3_80_': '80 g/m²',
    'a3_100_': '100 g/m²',
    'a3_120_': '120 g/m²',
    'a3_160_': '160 g/m²',
    'a3_200_': '200 g/m²',
    'a3_250_': '250 g/m²',
    'a3_300_': '300 g/m²',
    'a3_80_c': '80 g/m² Farbpapier',
    'a3_250_f': '250 g/m² Fotopapier',
    'a3_80_r': '80 g/m² Recyclingpapier',
    'p_80_': '80 g/m²',
    'p_140_m': '140 g/m² Matt',
    'p_200_m': '200 g/m² Matt',
    'p_200_g': '200 g/m² Glanz'
  };

  const [isColor, setIsColor] = useState(true);
  const [price, setPrice] = useState<number | null>(null);

  /**
   * Retrieves the available paper sort options based on the selected plot size,
   * ensuring appropriate filtering based on the defined conventions.
   * @returns {Record<string, string>} - A filtered set of paper sort options.
   */
  const getPaperSortOptions = (): Record<string, string> => {
    if (!plotSize) return {};
    /*  the plotSize is from a3 to a0, but in the convintion
        only the a3 prefix is available, the others are only p, 
        so we need to define the prefix differently 
    */
    const plotSizeToKeyPrefix = {
      A0: 'p',
      A1: 'p',
      A2: 'p',
      A3: 'a3'  // 'a3' directly corresponds to A3 size papers
    };
    const prefix = (plotSizeToKeyPrefix[plotSize] || 'default') + '_';
    const options = Object.keys(paperSorts).filter(key => key.startsWith(prefix)).reduce<Record<string, string>>((acc, key) => {
      acc[key] = paperSorts[key];
      return acc;
    }, {});
    return options;
  };

  /**
  * Determines the category of plot size for managing state transitions in UI.
  * @param {string} plotSize - The current plot size.
  * @returns {string} - A category identifier for the plot size.
  */
  const getCategory = (plotSize: "A0" | "A1" | "A2" | "A3" | "A4") => {
    /* to change papersorts for 
       [plotSize changes between ---> (a3 or less) to Plot
       and viceversa] 
    */
    if (plotSize === 'A3') {
      return 'A3';
    } else {
      return 'plot';
    }
  };

  /**
   * Handles changes in plot size selection, triggering updates to related states and UI components.
   * Updates the selected plot size, closes the menu, and resets the paper sort if the category changes.
   * @param {string} sizeKey - The selected plot size.
   */
  const handlePlotSizeChange = (sizeKey: "A0" | "A1" | "A2" | "A3"): void => {
    setPlotSize(sizeKey);
    setMenuOpen(prev => ({ ...prev, plotSize: false }));

    // Reset available paper sorts only on category change
    if (plotSize) {
      if (getCategory(plotSize) !== getCategory(sizeKey)) {
        setPaperSort(null);
        setPrice(null);
      }
    }
  };

  /**
   * Handles changes in paper sort selection, updating the state and closing the menu.
   * @param {string} sortKey - The selected paper sort.
   */
  const handlePaperSortChange = (sortKey: string) => {
    setPaperSort(sortKey);
    setMenuOpen(prev => ({ ...prev, paperSort: false }));
  };

  /**
   * Resets all selections to initial values, for now used when loading new documents or resetting the UI.
   */
  const resetSelectedMenuOptions = () => {
    setPlotSize(null);
    setPaperSort(null);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  /**
   * Handles file events by delegating to inputFileProcessing functions for processing the file,
   * triggering state updates and UI changes based on the file's content and type.
   * @param {File} file - The file to be processed.
   */
  const handleFileEvent = async (file: File) => {
    setShowLoadingScreen(true); // Show loading screen immediately before processing starts
    processInputFile(
      file,
      setNumPages,
      setPageNumber,
      setPrice,
      setDroppedFile,
      setPixelCount,
      setTotalPixels,
      setIsFileValid,
      setErrorMessage,
      t // translation function
    );
    // After the initial file processing is done, check for dimensions if the file is valid
    if (file) {
      await handleFileToGetDimensions(file);
    }
  };

  /**
   * Handles the dropping of files onto the designated area, ensuring only one file is processed
   * and triggering the file event handler.
   * @param {React.DragEvent<HTMLDivElement>} e - The drag event containing the files.
   */
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    resetSelectedMenuOptions();
    const files = e.dataTransfer.files;
    if (files.length === 1) {
      handleFileEvent(files[0]);
    } else {
      setIsFileValid(false);
      setErrorMessage(t('file-number-alert'));
    }
  };

  /**
   * Handles changes to the file input element, extracting the file and delegating to the file event handler.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetSelectedMenuOptions();
    const file = e.target.files?.[0];
    if (file) {
      handleFileEvent(file);
    }
  };

  /**
    * Initializes document-specific state settings upon loading a new document.
    * This includes setting the total number of pages, resetting the current page number
    * to the first page, clearing any set price, and resetting menu selections.
    * @param {number} numPages - Total number of pages in the loaded document.
  */
  const setUpDocumentDefaults = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setPrice(null);
    resetSelectedMenuOptions();
    setPixelCount(0);
  };

  const handleFileToGetDimensions = async (file: File) => {
    try {
      if (file.type === "application/pdf") {
        const dimensions = await getPdfDimensions(file);
        console.log("Dimensions:", dimensions);
        setFileDimensions(dimensions);
      } else {
        const dimensions = await getImageDimensions(file);
        setFileDimensions(dimensions);
      }
    } catch (error) {
      console.error("Error processing file:", error);
    }
  };

  // Calculate percentage of non-white pixels
  const percentage = totalPixels > 0 ? ((pixelCount / totalPixels) * 100).toFixed(2) : 0;

  // This effect runs whenever pixelCount or totalPixels changes
  useEffect(() => {
    if (plotSize && paperSort && percentage) {  // Ensure both plotSize and paperSort are not null
      calculatePrice(plotSize, paperSort, percentage, isColor, setPrice);
    }
  }, [plotSize, paperSort, percentage, isColor, setPrice]);


  /**
   * Effect hook to monitor the presence of a non-empty file and adjust the Upload-Button accordingly.
   */
  useEffect(() => {
    if (droppedFile.file) {
      setButtonPosition("top");
    } else {
      setButtonPosition("bottom");
    }
  }, [droppedFile]);


  /**
   * Prevents default behavior for context menu and text selection events,
   */
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => { e.preventDefault(); });
    document.addEventListener("selectstart", (e) => { e.preventDefault(); });
  }, []);

  /**
   * Adjusts the vertical alignment of content in the left container based on its scroll state,
   * ensuring that content does not clip out of view when the container is resized.
   */
  useEffect(() => {
    /* the css style I wanted couldn't be done with css, so I do it with js
       the container's justify-content should be centered,
       but that messes up the left-container's items from top 
       (elemnts get cropped from top if window gets smaller vertically),
       so we turn it into justify-content to start if a file dropped
    */
    const leftContainer = document.getElementById('left');
    const adjustJustifyContent = () => {
      if (!leftContainer) return;

      if (leftContainer.scrollHeight > leftContainer.clientHeight) {
        leftContainer.style.justifyContent = 'flex-start'; // Activates if content overflows
      } else {
        leftContainer.style.justifyContent = 'center'; // Default state when no overflow
      }
      // Additional effect to center content when a file is dropped
      if (!droppedFile) {
        leftContainer.style.justifyContent = 'flex-start';
      }
    };
    // Run on load and on resize
    adjustJustifyContent();
    window.addEventListener('resize', adjustJustifyContent);
    return () => {
      window.removeEventListener('resize', adjustJustifyContent); // Cleanup listener on component unmount
    };
  }, [droppedFile]);

  /**
   * Effect hook to manage the loading screen visibility based on the pixel count
   */
  useEffect(() => {
    if (pixelCount === 0) { setPrice(null); }
    else if (pixelCount > 0) {
      setShowLoadingScreen(false);
    }
  }, [pixelCount]);


  // Render the app
  return (
    <div className="app">
      <Header />
      {showLoadingScreen && <LoadingScreen isLoading={showLoadingScreen} />}
      <div
        className="container"
        ref={containerRef}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* Left side of the container */}
        <div id="left"
          className={`left ${isDragging ? "dragging" : ""} ${errorMessage ? "invalid" : isFileValid ? "valid" : ""}`}
        >
          {/* Add the upload button */}
          <button className={`upload-button ${buttonPosition}`} onClick={() => {
            const fileInput = document.getElementById("file-input");
            if (fileInput) {
              fileInput.click();
            }
          }}
          >
            {t('upload-file-types')}
          </button>

          {droppedFile.file ? (
            <>
              {/* Add the navigation div */}
              <div className="navigation">
                <button onClick={() => {
                  if (pageNumber > 1) {
                    setPrice(null);
                    setShowLoadingScreen(true);
                    setPageNumber(Math.max(pageNumber - 1, 1));
                    setPixelCount(0);
                  }
                }}
                >
                  {"<"}
                </button>
                <span>
                  {t('page')} {pageNumber} {t('of')} {numPages}
                </span>
                <button onClick={() => {
                  if (pageNumber < numPages) {
                    setPrice(null);
                    setShowLoadingScreen(true);
                    setPageNumber(Math.min(pageNumber + 1, numPages));
                    setPixelCount(0);
                  }
                }}
                >
                  {">"}
                </button>
              </div>

              {/* start ----------- FileNameType Row -----------*/}
              <div className="left-container-informationholder">
                {/* -------------- Pixel (category) -----------*/}
                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '33%', marginLeft: '10px', textAlign: 'left', paddingLeft: '5px' }}>Dateiname</td>
                    <td style={{ width: '33%' }}>
                      <div style={{
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '90%',
                        textAlign: 'center'
                      }}
                        title={droppedFile.name.split('.').slice(0, -1).join('.')}
                      >
                        {droppedFile.name.split('.').slice(0, -1).join('.')}
                      </div>
                    </td>
                    <td style={{ width: '33%', marginLeft: '10px', textAlign: 'right', paddingRight: '5px' }}>.{droppedFile.type.split('/').pop()}</td>
                  </tr>
                </table>

                {/* --------------- Pixel (category)  Row -----------*/}
                <table className='result-main-table'>
                  <tr style={{}}>
                    <td style={{ width: '50%', marginLeft: '10px', textAlign: 'left', borderBottom: '1px solid #f2f2f2', paddingLeft: '5px' }}>Pixelabdeckung:</td>
                    <td style={{ width: '50%', borderBottom: '1px solid #f2f2f2', paddingRight: '5px' }}>
                      <p className='result-to-show'>{percentage}%</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%', marginLeft: '10px', textAlign: 'left', paddingLeft: '5px' }}>Preiskategorie:</td>
                    <td style={{ width: '50%', paddingRight: '5px' }}>
                      <p className='result-to-show'>
                        {Number(percentage) <= CAD_THRESHOLD ? "Linienbetont" : (Number(percentage) <= GRAPHIC_THRESHOLD ? "Teildeckend" : "Vollflächig")}
                      </p>
                    </td>
                  </tr>
                </table>

                {/* start ----------- PlotSIZE -----------*/}
                <p className='percentage-info-text'>
                  Bis {CAD_THRESHOLD}% Linienbetont<br></br>{CAD_THRESHOLD + 1}% - {GRAPHIC_THRESHOLD}% Teildeckend &nbsp;&nbsp;|&nbsp;&nbsp; ab {GRAPHIC_THRESHOLD + 1}% Vollflächig
                </p>
                <table className='result-main-table'>
                  <tbody className='deminsion-table'>
                    {currentDimensions ? (
                      <tr style={{ padding: '' }}>
                        <td style={{ width: '30%', textAlign: 'left', paddingLeft: '5px' }}>Seitengröße: </td>
                        <td style={{ width: '30%', textAlign: 'center' }}>
                          <p>B: {currentDimensions.widthInMM} mm</p>
                        </td>
                        <td style={{ width: '30%', textAlign: 'right', paddingRight: '5px' }}>
                          <p>H: {currentDimensions.heightInMM} mm</p>
                        </td>
                      </tr>

                    ) : (
                      <tr>
                        <td colSpan={3}>Seitenmaße werden geladen...</td>
                      </tr>
                    )}
                  </tbody>
                  <tr className='plot-size-row'>
                    <td style={{ width: '30%', textAlign: 'left', paddingLeft: '5px' }}>Druckgröße:</td>
                    <td style={{ width: '30%' }}>
                      <div className='menu-cell'>
                        {!isMenuOpen.plotSize && <p className='menu-link' onClick={() => setMenuOpen(prev => ({ plotSize: !prev.plotSize, paperSort: false }))}>Wähle aus</p>}
                        {isMenuOpen.plotSize &&
                          <MenuSelector
                            onOptionChange={handlePlotSizeChange as (key: string) => void}
                            isMenuOpen={isMenuOpen.plotSize}
                            options={plotSizes as Record<string, string>}
                            closeMenu={() => setMenuOpen(prev => ({ ...prev, plotSize: false }))}
                          />}
                      </div>
                    </td>
                    <td style={{ width: '30%', textAlign: 'left', paddingRight: '5px' }}>
                      <p className='result-to-show'>{plotSize ? plotSizes[plotSize] : ' - '}</p>
                    </td>
                  </tr>
                </table>
                {/* start ----------- PAPER -----------*/}
                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '30%', textAlign: 'left', paddingLeft: '5px' }}>Grammatur:</td>
                    <td style={{ width: '30%' }}>
                      <div className='menu-cell'>
                        <p className={`menu-link ${!plotSize ? 'disabled-menu' : ''}`}
                          onClick={() => {
                            if (plotSize) {
                              setMenuOpen(prev => ({ plotSize: false, paperSort: !prev.paperSort }));
                            }
                          }}>
                          Wähle aus
                          {!plotSize && <span className="tooltip">Bitte wählen Sie zuerst eine Größe aus</span>}
                        </p>
                        {isMenuOpen.paperSort &&
                          <MenuSelector
                            onOptionChange={handlePaperSortChange}
                            isMenuOpen={isMenuOpen.paperSort}
                            options={getPaperSortOptions()}
                            closeMenu={() => setMenuOpen(prev => ({ ...prev, paperSort: false }))}
                          />}
                      </div>
                    </td>
                    <td className="result-to-show-cell" style={{ width: '30%', textAlign: 'left', paddingRight: '5px' }}>
                      <p className='result-to-show'>
                        {paperSort ? paperSorts[paperSort] : ' - '}
                      </p>
                    </td>
                  </tr>
                </table>
                {/* start ----------- COLOR -----------*/}
                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '30%', textAlign: 'right' }}>
                      <p className="gray-gradient">S/W</p>
                    </td>
                    <td className="table-cell">
                      <label className="switch">
                        <input type="checkbox"
                          defaultChecked={true}
                          onChange={(e) => {
                            setIsColor(e.target.checked);
                            setPrice(null);
                          }
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td style={{ width: '30%', textAlign: 'left' }}>
                      <p className="rainbow">Farbig</p>
                    </td>
                  </tr>
                </table>
              </div>
              {/* End----------- File information -----------*/}

              { }
              <div style={{ alignItems: 'center', margin: '15px' }}>
                <button onClick={() => window.location.reload()}>{t('refresh-page')}</button>
              </div>
              <table className='price-div'>
                <tr>
                  <td ><p>Preis: {price ? price.toFixed(2) : ' - '}€</p></td>
                </tr>
              </table>
            </>
          ) : (
            <p className='drag-here-text'> {t("drag-msg")}</p>
          )}
          {/* Add the error message */}
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
        {/* Right side of the container */}
        <div className="right" style={{ margin: "10px" }}>
          <div className="pdf-container">
            {droppedFile.file ? (
              <>
                {/* Render the PDF */}
                {droppedFile.type === "application/pdf" ? (
                  <Document className={"pdf-loader"} file={droppedFile.file} onLoadSuccess={setUpDocumentDefaults}>

                    <div className='pdf-orig-hiddden-size'>
                      {/* this is just for calculations, I coullnd't find a better way to do it  */}
                      <Page
                        pageNumber={pageNumber}
                        onLoadSuccess={
                          (page) => processOffScreenPdfPage(page, setPixelCount, setTotalPixels)
                        }
                      />
                    </div>
                    <div style={{}}>
                      <Page
                        pageNumber={pageNumber}
                        width={scaledWidth}
                        height={scaledHeight}
                      />
                    </div>
                  </Document>
                ) : (
                  <img
                    className={"other-image"}
                    src={droppedFile.file ? URL.createObjectURL(droppedFile.file) : ""}
                    alt=""
                    style={{ maxWidth: scaledWidth, height: 'auto' }}
                  />
                )}
              </>
            ) : (
              <p className='welcome-text'>
                {t("welcome-txt").split('\n').map((line: string, i: number) => <span key={i}>{line}<br /></span>)}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* Add the German message here */}

      <Footer />
      <input id="file-input" type="file" accept=".pdf,.png,.jpg,.jpeg" onChange={handleFileInputChange} style={{ display: "none" }} />
    </div >
  );
}

export default App;
