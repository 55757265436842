import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "de", // language to use
  resources: {
    en: {
      translation: require("./locales/en.json"),
    },
    de: {
      translation: require("./locales/de.json"),
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
