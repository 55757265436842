/**
 * A functional component that provides a visual feedback of an ongoing process by displaying
 * a loading animation and messages. It utilizes CSS-based animations to smoothly transition
 * the loading screen into and out of view.
 * 
 * Props:
 *   - isLoading: A boolean indicating whether the loading screen should be active.
 * 
 * The component uses react-i18next for internationalization, allowing it to display translated
 * loading messages. It implements a fade-out effect when the loading process ends, enhancing
 * the user experience by preventing abrupt disappearance of the loading screen.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isLoading - Controls visibility of the loading screen based on the loading state.
 * @returns {JSX.Element|null} - The loading screen element or null if it should not be displayed.
 */
import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
import { useTranslation } from 'react-i18next';

const LoadingScreen = ({ isLoading }: { isLoading: boolean }) => {
    // Access the translation function from react-i18next for localizing content.
    const { t } = useTranslation();

    const [visible, setVisible] = useState(isLoading); // State to manage the visibility of the component with a fade-out effect.

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isLoading) {
            setVisible(true);
        } else {
            timer = setTimeout(() => setVisible(false), 500); // Corresponds to the animation duration
        }

        return () => clearTimeout(timer);  // Clean up the timer when the component unmounts or isLoading changes.
    }, [isLoading]);

    if (!visible) return null;  // Do not render the component if it should not be visible.

    // if (!isLoading) {
    //     return null;
    // }

    return (
        <div className="loading-overlay">
            <div className="loading-content">
                <img src="/landing-page-2.gif" alt="Loading..." className="loading-image" />
                <p>{t('loading-msg')}</p>
                <p className="loading-detail-msg">{t('loading-detail-msg')}</p>
            </div>
        </div>
    );
};

export default LoadingScreen;