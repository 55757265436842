import React, { useRef, useEffect } from 'react';
import './CustomMenu.css';


/**
 * Props for the CustomMenu component.
 * @typedef CustomMenuProps
 * @type {object}
 * @property {(key: string) => void} onOptionChange - Callback to execute when an option is selected.
 * @property {boolean} isMenuOpen - State indicating whether the menu is open.
 * @property {Record<string, string>} options - Record of menu options to display.
 * @property {() => void} closeMenu - Callback to close the menu.
 */
interface CustomMenuProps {
  onOptionChange: (key: string) => void;
  isMenuOpen: boolean;
  options: Record<string, string>;
  closeMenu: () => void;
}

/**
 * A component that renders a dropdown menu with options provided via props.
 * The menu visibility is controlled by `isMenuOpen`. It uses a React ref to handle
 * clicks outside the menu for closing it.
 * 
 * @param {CustomMenuProps} props - The properties passed to the component.
 * @returns A React Element or null if the menu is not open.
 */
function CustomMenu({ onOptionChange, isMenuOpen, options, closeMenu }: CustomMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);

  // Effect to handle clicks outside of the menu to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Close the menu if the click is outside the menu element
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    // Attach the event listener if the menu is open
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    // Cleanup the event listener when the component unmounts or menu closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen, closeMenu]);

  // Do not render anything if the menu is not open
  if (!isMenuOpen) {
    return null;
  }

  return (
    <div className="option-menu" ref={menuRef}>
      {Object.entries(options).map(([key, value]) => (
        <div
          key={key}
          className="option-menu-item"
          onClick={() => {
            onOptionChange(key);
            closeMenu();
          }}
        >
          {value}
        </div>
      ))}
    </div>
  );
}

export default CustomMenu;
