import * as pdfjs from 'pdfjs-dist';


/**
 * Types for callback functions used to update the application state.
 */
type SetNumPagesType = (numPages: number) => void;
type SetPageNumberType = (pageNumber: number) => void;
type SetPriceType = (price: number | null) => void;
type SetDroppedFileType = (fileData: { file: File; name: string; type: string }) => void;
type SetPixelCountType = (count: number) => void;
type SetTotalPixelsType = (count: number) => void;
type SetIsFileValidType = (isValid: boolean) => void;
type SetErrorMessageType = (message: string | null) => void;

/**
 * Processes an input file by determining its type and handling it appropriately.
 * Supports PDF, PNG, and JPEG formats. The function handles file validation,
 * PDF page count retrieval, and image data extraction to count non-white/non-transparent pixels.
 *
 * @param {File} file - The file to be processed.
 * @param {SetNumPagesType} setNumPages - Function to set the number of pages for PDFs.
 * @param {SetPageNumberType} setPageNumber - Function to set the current page number.
 * @param {SetPriceType} setPrice - Function to reset or set the price based on file processing.
 * @param {SetDroppedFileType} setDroppedFile - Function to set the file information in the state.
 * @param {SetPixelCountType} setPixelCount - Function to set the count of non-white pixels.
 * @param {SetTotalPixelsType} setTotalPixels - Function to set the total number of pixels processed.
 * @param {SetIsFileValidType} setIsFileValid - Function to update the validity status of the file.
 * @param {SetErrorMessageType} setErrorMessage - Function to display error messages.
 * @param {(key: string) => string} t - Translation function for localizing messages.
 */
export const processInputFile = async (
    file: File,
    setNumPages: SetNumPagesType,
    setPageNumber: SetPageNumberType,
    setPrice: SetPriceType,
    setDroppedFile: SetDroppedFileType,
    setPixelCount: SetPixelCountType,
    setTotalPixels: SetTotalPixelsType,
    setIsFileValid: SetIsFileValidType,
    setErrorMessage: SetErrorMessageType,
    t: (key: string) => string
) => {
    // Early exit if file type is not supported.
    if (file.type !== "application/pdf" && file.type !== "image/png" && file.type !== "image/jpeg") {
        setIsFileValid(false);
        setErrorMessage(t('file-type-alert'));
        return; // Early exit if file type is not supported
    }

    // Reset pricing information
    setPrice(null);

    if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        // File supported, proceed with processing
        setDroppedFile({
            file: file,
            name: file.name,
            type: file.type,
        });

        // Specific processing for PDF files
        if (file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onload = (event) => {
                const pdfData = event.target?.result;
                const loadingTask = pdfjs.getDocument({ data: pdfData as Uint8Array });
                loadingTask.promise.then((pdf) => {
                    setNumPages(pdf.numPages);
                    setPageNumber(1);
                });
            };
            reader.readAsArrayBuffer(file);
        }
        // Image file processing
        else {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx!.drawImage(img, 0, 0);
                const imageData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
                let totalPixels = 0;
                let nonWhitePixels = 0;
                for (let i = 0; i < imageData.data.length; i += 4) {
                    totalPixels++;
                    if (
                        imageData.data[i] !== 255 ||
                        imageData.data[i + 1] !== 255 ||
                        imageData.data[i + 2] !== 255 ||
                        imageData.data[i + 3] !== 255
                    ) {
                        nonWhitePixels++;
                    }
                }
                setPixelCount(nonWhitePixels);
                setTotalPixels(totalPixels);
            };
            img.src = URL.createObjectURL(file);
            setNumPages(1);
            setPageNumber(1);
        }

        // File is valid and successfully processed
        setIsFileValid(true);
        setErrorMessage(null);
    } else {

        // File is NOT valid and shows an error message
        setIsFileValid(false);
        setErrorMessage(t('file-type-alert'));
    }
};
