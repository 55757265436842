import * as pdfjs from 'pdfjs-dist';
import { PageDimensions } from './types';

export const getPdfDimensions = async (file: File): Promise<PageDimensions[]> => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onload = async (event) => {
            try {
                const typedArray = new Uint8Array(event.target?.result as ArrayBuffer);
                const loadingTask = pdfjs.getDocument(typedArray);
                const pdf = await loadingTask.promise;
                const dimensions: PageDimensions[] = [];

                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1 });
                    const widthInMM = Math.round((viewport.width / 72) * 25.4);
                    const heightInMM = Math.round((viewport.height / 72) * 25.4);
                    dimensions.push({ pageNum, widthInMM, heightInMM });
                }
                resolve(dimensions);
            } catch (error) {
                reject(error);
            }
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(file);
    });
};
