import { useTranslation } from 'react-i18next';
import './Header.css';

/**
 * A header component for displaying the application's top navigation bar.
 * Includes the company logo on the left, a placeholder for additional content in the middle,
 * and navigation links on the right.
 *
 * Utilizes the 'react-i18next' library for internationalization to provide localized text
 * for the navigation links.
 *
 * @returns {JSX.Element} The rendered header component.
 */
function Header() {
    // Access the translation function from react-i18next for localizing content.
    const { t } = useTranslation();

    return (
        <div className="header">
            <div className="header-left">
                <img src="dh-header-logo.png" alt="Druckhaus-Logo" style={{ height: "50px", width: "auto" }} />
            </div>
            <div className="header-mid">
                {/* Mid place holder for later*/}
            </div>
            <div className="header-right">
                <p className='menu'>
                    <span className="pipe"> | </span>
                    <a href='https://www.druckhaus.co/'>
                        {t('home-btn')}
                    </a>
                    <span className="pipe"> | </span>
                    <a href='https://www.copyshop-bochum.de/#contact'>
                        {t("contact-btn")}
                    </a>
                    <span className="pipe"> | </span>
                </p>
            </div>
        </div>
    );
}

export default Header;