import { PageDimensions } from './types';

export const getImageDimensions = (file: File): Promise<PageDimensions[]> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            // Assume 72 DPI for the image
            const dpi = 72;
            const widthInMM = Math.round((img.width / dpi) * 25.4);
            const heightInMM = Math.round((img.height / dpi) * 25.4);

            resolve([{ pageNum: 1, widthInMM, heightInMM }]);
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
};